




import { Component, Vue } from 'vue-property-decorator'
import BlogPostDetail from './components/BlogPostDetail.vue'

@Component({
  name: 'CreateBlogPost',
  components: {
    BlogPostDetail
  }
})
export default class extends Vue {}
